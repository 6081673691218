import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Link } from 'react-router-dom'
// import FileUpload from './components/FileUpload';
import moment from "moment";
import { AgGridReact } from 'ag-grid-react';
import _ from "lodash";
import Button from '@material-ui/core/Button';

import FileUpload from "../../../../app/modules/assets/components/PurchasedFileUpload"
import { directUSAPICall, SVPercentageformatter, SVformatter, generateExcel, FormatFilterValues } from "../utils"
import { PURCHASEDASSETSOVERVIEW, PURCHASEDASSETS, INVENTORYPRESETSFIELDS } from "../constant";
import DeleteConfirmDialog from "../../components/deleteConfirm"
import { useStyles } from "./components/utils";
import { useParams } from 'react-router-dom';
import AssetViewDialog from '../../../../app/modules/assets/components/AssetViewDialog'
import ExportModal from './components/ExportData';
import Drawer from '../../../../app/modules/components/sidedrawer';
import CustomStatsToolPanel from "./components/Customepanel";
import LifeCycleDialog from '../aggrid/components/Lifecycle_settings'
import NotesDialog from './components/NotesDialog'
import { UpdateFilter, getFilters, CreateFilter, UpdatePresets, CreatePresets } from '../../../../app/modules/assets/redux/AssetsCrud'


export default function AssetForm(props) {
  const {
    setTotalAssets,
    searchString,
    page,
    searchcolumns,
    currentpage,
    inventoryData
  } = props;
  const classes = useStyles();
  const urlParams = useParams();
  const overlayLoadingTemplate =
    '<span className="ag-overlay-loading-center">Loading, Please Wait...</span>';
  const noRowsTemplate =
    '<span className="ag-overlay-loading-center">No records found...</span>';
  const [showNotesDialog, setShowNotesDialog] = useState(false);
  const [fileUploadOpen, setFileUploadOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [gridApi, setGridApi] = useState();
  const [quickFilterText, setQuickFilterText] = useState('');
  const [rowDataAPI, setRowDataAPI] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [enableBulkUpdates, setEnableBulkUpdates] = useState(false);
  const [bulkUpdateOpen, setBulkUpdateOpen] = useState(false);
  const [tablecolumns, setTableColumns] = useState([])
  const [selectedAsset, setSelectedAsset] = useState();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState([]);
  const { data } = useSelector(({ auth }) => auth.user);
  const [userInfo, setUserInfo] = useState(data);
  const [showAssetDetail, setShowAssetDetail] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState('');
  const [userPermission, setUserPermission] = useState({
    canEdit: true,
    canDelete: true,
    canView: true,
    canAdd: true,
    canImport: true,
    canBulkDelete: true,
    canImport: true,
    canBulkUpdate: true,
    canNotes: true
  });
  const [selectedColumns, setSelectedColumns] = React.useState([]);

  const [totalRowDatas, setTotalRowDatas] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(500);

  const [rowDataTemp, setRowDataTemp] = useState([]);
  const [tempColumnDefs, setTempColumnDefs] = useState([]);
  const [tempColumns, setTempColumns] = useState([]);
  const [tempFieldsList, setTempFieldsList] = useState([]);
  const [totalAssetValue, setTotalAssetValue] = useState('');
  const [dataSourceparams, setDataSourceparams] = useState(null);
  const gridRef = useRef();
  const [showExport, setShowExport] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [showLifecycle, setshowLifecycle] = useState(false)
  const [isSelect, setIsSelect] = useState(true)
  const [columnDefs, setColumnDefs] = useState([]);
  const [presetId, setPresetId] = React.useState()


  useEffect(() => {
    if (columnDefs?.length > 0 && dataSourceparams && inventoryData?.length > 0) {
      dataSourceparams.successCallback(inventoryData, inventoryData.length);
    }
  }, [dataSourceparams, columnDefs, inventoryData])


  const getPresets = async (params, page) => {
    // if (userInfo?.userType !== "SUPERADMIN" && userInfo?.userType !== "ADMINUSER") {
    //   callLists()
    //   return
    // }
    // if (Object.keys(params.filterModel).length > 0) {
    //               lists(gridApi, null, tempFieldsList, params, page)

    //   return
    // }
    let param = `?filter={"_and":[{"collection":{"_eq":"purchased_assets"}},{"user":{"id":{"_eq":"${userInfo.id}"}}}]}`

    getFilters(param)
      .then(({ data: { data } }) => {
        if (data && data.length > 0) {
          setPresetId(data[0].id)
          if (data[0].layout_query1?.tabular?.fields?.length > 0) {
            let tempFields = data[0].layout_query1.tabular.fields;
            let val = tempColumnDefs.filter((row) => tempFields.indexOf('' + row.field) !== -1)
            tempColumnDefs.forEach((item) => {
              if (tempFields.some((x) => x == item.field)) {
                item.checked = true;
              } else {
                item.checked = item.field === 'actions' ? true : false;
              }
            })
            setTempColumns(tempColumnDefs)
            setColumnDefs(val);
            let cols = []
            val.map((item) => {
              let obj = {};
              if (item.field !== 'actions' && !item.hide) {
                obj[item.field] = ""
                obj = {
                  'key': item.field,
                  'value': "",
                  'placeholder': item.header_name,
                  'operator': item.operator,
                  'type': item.type
                }
                cols.push(obj)
              }
            })
            setTempFieldsList(tempFields);
            if (!inventoryData) {
              lists(gridApi, params)
            }
          } else {
            setTempColumns(tempColumnDefs)
            callLists(params, page);
            setLoading(true);

          }
        } else {
          setTempColumns(tempColumnDefs)
          callLists(params, page);
        }
      }).catch((err) => {
        console.log("erroorr", err)
        if (!inventoryData) {
          lists(gridApi, params)
        }
      })
  }

  const callLists = (request, page) => {
    let val = [];
    let tempCol = tempColumnDefs

    if (userInfo?.userType === "SUPERADMIN" || userInfo?.userType === "ADMINUSER") {
      val = tempCol.filter((row) => row.adminview);
    } else if (userInfo?.userType == "CLIENT") {
      val = tempCol.filter((row) => row.clientview);
    } else if (userInfo?.userType == "PARTNER") {
      val = tempCol.filter((row) => row.partnerview);
    }

    let tempFields = val.map(
      (row) => row.field
    );
    tempCol.forEach((item) => {
      if (tempFields.some((x) => x == item.field)) {
        item.checked = true;
      } else {
        item.checked = item.field === 'actions' ? true : false;
      }
    })
    setTempColumns(tempCol)
    setColumnDefs(val)
    let cols = []
    val.map((item) => {
      let obj = {};
      if (item.field !== 'actions' && !item.hide) {
        obj[item.field] = ""
        obj = {
          'key': item.field,
          'value': "",
          'placeholder': item.header_name,
          'operator': item.operator,
          'type': item.type
        }
        cols.push(obj)
      }
    })
    setTempFieldsList(tempFields)
    if (!inventoryData) {
      lists(gridApi, request, null, null)
    }
  }

  const lists = async (gridApi, request = dataSourceparams, exportlimit = false, searchvalue, isimport = null) => {
    if (!exportlimit) {
      setLoading(true);
      gridApi && gridApi.showLoadingOverlay();
    }
    let fields = currentpage === 'overview' ? PURCHASEDASSETSOVERVIEW : PURCHASEDASSETS

    setRowData([]);

    let sort = '-pid';

    const currentpage1 = request.endRow / rowsPerPage;
    let options = {
      limit: rowsPerPage,
      sort: sort,
      fields: [fields],
      page: currentpage1,
      meta: ['total_count', 'filter_count'],
      filter: {
        _and: [
          {
            "status": {
              _nnull: true
            }
          }
        ]
      }
    }
    if (userInfo.role) {
      if ((userInfo?.role?.name === "client_admin") || (userInfo?.role?.name === "client_user")) {
        options.filter['_and'].push({
          "client": {
            _eq: userInfo.client.id
          }
        })
      }
    }
    if (urlParams?.year) {
      options.filter['_and'].push({
        "year(end_of_lifecycle)": {
          _eq: urlParams?.year
        }
      })
    }
    if (urlParams?.equipment) {
      options.filter['_and'].push({
        "equipment": {
          _eq: urlParams?.equipment
        }
      })
    }
    if (urlParams?.id) {
      options.filter['_and'].push({
        "pid": {
          _eq: urlParams?.id
        }
      })
    }
    //Find the search values
    if (searchvalue) {
      let string = searchvalue.split(" ");
      let filters = {
        _and: []
      }
      string.map((obj) => {
        filters['_and'].push({
          "searchfield": {
            _contains: `-${obj.toLowerCase()}`
          }
        })
      })
      options.filter['_and'].push(filters)
      // options1.filter['_and'].push(filters)
    }
    if (request && Object.keys(request.filterModel).length > 0) {
      const filterVal = FormatFilterValues(request.filterModel, 'inventory');
      let filterParam = options.filter['_and'] ? [...options.filter['_and'], ...filterVal] : [...filterVal];
      options.filter['_and'] = filterParam
    } else {
      if (options.filter['_and']?.length > 0) {
        options.filter['_and'] = [...options.filter['_and']]
      }
    }
    const result = await directUSAPICall().items('purchased_assets').readMany(options);
    setTotalRowDatas(result.meta.filter_count);
    let response = result.data;
    request.successCallback(response, result.meta.filter_count);

    if (result.data && result.data.length > 0) {
      if (!exportlimit) {
        setLoading(false)
        if (currentpage === 'overview') {
          setTotalAssets(response)
        }
        setRowDataTemp(response)
        request.successCallback(response, result.meta.filter_count);
        gridApi && gridApi.hideOverlay();
      } else {

        setIsDownloaded(true)
        exportExcelData(response)
      }
    } else {
      request.successCallback([], 0);
      setLoading(false);
      request.failCallback();
      gridApi && gridApi.hideOverlay();
    }
  }

  const getColumnDefinitions = async (gridApi, page) => {
    let options = {
      limit: -1,
      sort: 'order_by_id',
      // fields: [fields]
    }
    if (currentpage === 'overview') {

      options.filter = {
        _and:
          [
            {
              "dashboardview": {
                _eq: true
              }
            }
          ]
      }
    }
    const results = await directUSAPICall().items('Asset_purchased_Column_Definitions').readMany(options).then((result) => {
      if (result) {
        // setColumnDefs(result.data)
        result.data.forEach((item) => {
          if (item.field === 'description' && currentpage !== 'overview') {
            item.component = ''
          }
        })
        setTempColumnDefs(result.data)
      }
    }).catch((err) => {
    })
  }

  const deleteConfirm = (values) => {
    setShowDeleteDialog(true)
    let arr = [];
    arr.push(values.pid)
    setDeleteId(arr);
    // let rowDataCopy = [...rowDataTemp];
    // rowDataCopy.splice(deleteIndex, 1);
    // setRowData(rowDataCopy);
  }

  const handleBulkDelete = async (props) => {
    let selectedRows = gridApi.getSelectedRows();
    let ids = (selectedRows.map((item) => item.pid));
    setDeleteId(ids)
    setShowDeleteDialog(true)
  };

  const ActionRendererComponent = ({ data }) => {
    let props = data
    return (
      props ?
        <div>
          {
            <i onClick={() => { setSelectedAssetId(props.pid); gotoEdit(props, 'edit') }} className="fas fa-edit" />
          }
          <i className={`${props?.notes ? 'fas' : 'far'} fa-sticky-note`} onClick={() => handleNotestOpen(props)}></i>

          {(userPermission && userPermission.canDelete) && <i className="fas fa-trash-alt deleteone" onClick={() => deleteConfirm(props)} />}
        </div> : null
    );
  }

  let TextFilterParams = {
    filterOptions: ['contains'],
    textFormatter: (r) => {
      if (r == null) return null;
      return r
        .toLowerCase()
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y');
    },
    debounceMs: 200,
    maxNumConditions: 1,
  };

  let EqualFilterParams = {
    filterOptions: ['equals'],
    textFormatter: (r) => {
      if (r == null) return null;
      return r
        .toLowerCase()
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y');
    },
    debounceMs: 200,
    maxNumConditions: 1,
  };

  const LinkCellRenderer = (params) => {
    return <>
      {
        <Link target="_blank" to={`/inventory-list/${params?.data?.pid}`}>{params.value}</Link>
      }
    </>
  }
  const buildColumnDefinitions = (columnDefs) => {
    return columnDefs && columnDefs.map((columnDef, index) => {
      let columnDefinition = {
        headerName: (index !== 0) ? columnDef.header_name : currentpage === 'overview' ? columnDef.header_name : '',
        cellRenderer: columnDef.component ? columnDef.component : false,
        // headerCheckboxSelectionFilteredOnly: currentpage !== 'overview' ? true : false,
        checkboxSelection: rowDataTemp.length > 0 ? ((index === 0 && currentpage !== 'overview') ? true : false) : false,
        field: columnDef.field,
        editable: false,
        filter: columnDef.filter,
        sortable: true,
        resizable: false,
        suppressAggFuncInHeader: true,
        enableCellChangeFlash: true,
        filterParams: (columnDef.type === 'currencyColumn' || columnDef.type === 'number' || columnDef.type === 'date') ? EqualFilterParams : TextFilterParams,
        filterType: columnDef.type === 'currencyColumn' ? 'number' : columnDef.type,
        // hide: getMonthDiff(columnDef.field, params),
        hide: false,
        // width: index !== 0 ? ((columnDef.field === 'model') ? 400 : (columnDef.field.length <= 9 ? 90 : columnDef.field.length >= 12 ? 150 : columnDef.field.length >= 17 ? 160 : 180)) : 180,
        width: 'auto',
        // cellClass: columnDef.type === 'currencyColumn' ? 'ag-right-aligned-cell' : '',
        valueFormatter: (params) => {
          // console.log("params", params.data.aggrement)
          if (columnDef.type === 'currencyColumn') {
            return params.value ? SVformatter(params.value) + ' kr' : '';
          }
          if (params.value && columnDef.type === 'date') {
            return moment(params.value).format('YYYY-MM-DD');
          }
          if (columnDef.type === 'percentage') {
            let val = params.value ? Math.round(params.value * 100) / 100 : '';;
            return val ? SVPercentageformatter(val) : '0.00%';
          }

        },
        valueGetter: (params) => {
          if (params.data) {
            if (params?.data?.client) {
              params.data.client_name = params.data.client.name ? params.data.client.name : ''
              params.data.clients_org_no = params.data.client.clients_org_no ? params.data.client.clients_org_no : ''
            }
            if (params.data.supplier) {
              params.data.supplier_name = params.data.supplier.supplier_name
              params.data.supplier_org = params.data.supplier.supplier_org_no
            }
            if (params.data?.user_created) {
              let user = params.data.user_created
              params.data.created_by = user.first_name;
            }
            if (params.data?.user_updated) {
              let user = params.data.user_updated
              params.data.updated_by = user.first_name;
            }
            if (params.data?.price_total && params.data.lifecycle_months) {
              let monthlycost = Math.round((params.data.price_total / params.data.lifecycle_months));
              params.data.monthly_cost = monthlycost
            }
            if (columnDef.field === 'refurbished_model') {
              params.data.refurbished_model_obj = params.data.refurbished_model;
              let model = params.data.refurbished_model?.model ? params.data.refurbished_model?.model : params.data.refurbished_model
              return model;
          }
            return params.data[columnDef.field];
          }

        }

      };
      // if (columnDef.field === 'agrement_info_no') {
      //   let agremtList = agreementsInfoList && agreementsInfoList.sort((a, b) => (a > b ? -1 : 1));
      //   columnDefinition.cellEditor = 'DialogEditor';
      //   columnDefinition.cellEditorParams = {
      //     values: agremtList,
      //   };
      // }
      if (columnDef.type === 'date') {
        columnDefinition.cellEditor = 'dateEditor';
      }


      // if (columnDef.field === 'totalAssets') {
      //     columnDefinition.cellRenderer = params => {
      //         params.data.totalAssets = getAssetsCount(params.data.id)
      //         return params.data.totalAssets
      //     };

      // }
      return columnDefinition;
    });
  };

  const frameworkComponents = {
    ActionRendererComponent,
    LinkCellRenderer
  };

  const onModelUpdated = (params) => {
    // console.log("onModelUpdated" ,params)
    if (!enableBulkUpdates) {
      gridApi && gridApi.deselectAll()
    }
    // params.api.sizeColumnsToFit();
  };

  const onRowSelected = (params) => {
    setEnableBulkUpdates(gridApi?.getSelectedRows()?.length > 0);
  };
  const onRowDataChanged = (params) => {
    highlightUnsavedRows(params);
  };

  const highlightUnsavedRows = (params) => {
    if (!params || rowDataAPI.length === 0) {
      return;
    }
    let missingRowNodes = params.api.rowModel.rowsToDisplay.filter((row) => {
      if (!row.data.asset_id) {
        return row;
      }
    });

    if (missingRowNodes.length > 0) {
      missingRowNodes.map((node) => {
        if (params.node !== node) {
          node.setSelected(true);
        }
      });
    }
  };

  const onRowEditingStarted = (params) => {
    if (enableBulkUpdates) {
      return
    }
    gridApi.refreshCells({
      rowNodes: [params.node],
      // columns: [params.columnApi.columnController.allDisplayedColumns[0]],
      force: true,
    });
  };

  const handleNotestOpen = (params) => {
    setShowNotesDialog(true);
    setSelectedAsset(params)
  };

  const exportExcelData = async (exportData = []) => {
    generateExcel(gridApi, `Inventory-${moment().format('YYYY-MM-DD')}`, 'inventory', tablecolumns, null, selectedColumns, exportData);
    setShowExport(false)

  }

  const onGridReady = (params) => {
    getColumnDefinitions();
    setGridApi(params.api);
    // const columnsToolPanel = params.api.getToolPanelInstance('columns');
    // // expands all column groups in the Columns Tool Panel
    // columnsToolPanel.expandColumnGroups(['id', 'import_id']);
  };


  useEffect(() => {
    if (userInfo && gridApi && tempColumnDefs && tempColumnDefs.length > 0) {
      const dataSource = {
        getRows: (params) => {
          const page = params.endRow / rowsPerPage;
          getPresets(params, page)
          setDataSourceparams(params);
        }
      }
      gridApi.setDatasource(dataSource);
    }
  }, [userInfo && gridApi, tempColumnDefs]);


  const clearFilters = () => {
    gridApi.setFilterModel(null);
  }

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 225,
      floatingFilter: true,
    };
  }, []);

  const gotoEdit = (values, type = null) => {
    if (type === 'edit') {
      setSelectedAssetId(values.pid)
      setLoading(true)
      setShowAssetDetail(true)
    }
    //else {
    //   history.push(`/updateasset/${values.data.id}`)
    // }
  }
  // console.log("grid api", gridApi)
  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
            // onStateChange:()=>{

            // }
            onStateChange: function (item) {
              console.log("item.id", item.id)
              return item.id;
            },
          },

        },
        {
          id: "columns 2",
          labelDefault: "Columns 2",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: CustomStatsToolPanel,

        },

        // {
        //   id: "columns2",
        //   labelDefault: "Columns2",
        //   labelKey: "filter",
        //   iconKey: "menu",
        //   toolPanel: ()=>{
        //    <div>
        //      tesets
        //    </div>
        //   }
        // }, 


      ],
      defaultToolPanel: "columns",
    };
  }, []);

  const submitFilter = (isStdView = false) => {
    let selectedColumns = []
    if (isStdView) {
      if (userInfo?.userType === "SUPERADMIN" || userInfo?.userType === "ADMINUSER") {
        selectedColumns = tempColumns.filter((row) => row.stdview && row.field !== 'actions');
      } else if (userInfo?.userType == "PARTNER") {
        selectedColumns = tempColumns.filter((row) => row.partnerstdview && row.field !== 'actions');
      } else if (userInfo?.userType == "CLIENT") {
        selectedColumns = tempColumns.filter((row) => row.cientstdview && row.field !== 'actions');
      }
    } else {
      if (userInfo?.userType === "SUPERADMIN" || userInfo?.userType === "ADMINUSER") {
        selectedColumns = tempColumns.filter((row) => row.checked);
      } else if (userInfo?.userType == "PARTNER") {
        selectedColumns = tempColumns.filter((row) => row.checked);
      } else if (userInfo?.userType == "CLIENT") {
        selectedColumns = tempColumns.filter((row) => row.checked);
      }
    }
    setLoading(true)

    // return
    selectedColumns = selectedColumns.map(
      (status) => status.field
    );
    selectedColumns = ['actions', ...selectedColumns]
    let obj = {
      "status": 'published',
      // "role": currentUser.role.id,
      "user": userInfo.id,
      "filter": null,
      "collection": "purchased_assets",
      "layout_query1": {
        "tabular": {
          "page": 1,
          "fields": selectedColumns,
          "sort": [
            "-created_at"
          ],
          "limit": 300
        }
      },
    }
    if (selectedColumns?.length > 0) {
      let tempFields = selectedColumns;
      let val = tempColumnDefs.filter((row) => tempFields.indexOf('' + row.field) !== -1)
      tempColumnDefs.forEach((item) => {
        if (tempFields.some((x) => x == item.field)) {
          item.checked = true;
        } else {
          item.checked = item.field === 'actions' ? true : false;
        }
      })
      setTempColumns(tempColumnDefs)
      setColumnDefs(val);
    }
    if (presetId) {

      UpdateFilter(obj, presetId)
        .then(({ data: { data } }) => {
          setLoading(false)
          // lists(gridApi, null, selectedColumns)

          gridApi.hideOverlay();
        })
        .catch((err) => {
          setLoading(false)
          throw err;
        });

    } else {
      if (userInfo?.userType !== "SUPERADMIN" && userInfo?.userType !== "ADMINUSER") {
        CreatePresets(obj)
          .then((response) => {
            if (response?.data?.data) {
              setPresetId(response.data.data.id);
              lists(gridApi, dataSourceparams, null, null, selectedColumns)
            } else {
              lists(gridApi, dataSourceparams, null, null, selectedColumns)
            }
          })
          .catch((err) => {
            setLoading(false)
            throw err;
          });
      } else {
        CreateFilter(obj)
          .then((response) => {
            if (response?.data?.data) {
              setPresetId(response.data.data.id);
              lists(gridApi, dataSourceparams, null, null, selectedColumns)
            } else {
              lists(gridApi, dataSourceparams, null, null, selectedColumns)
            }
            // gridApi.hideOverlay();
          })
          .catch((err) => {
            lists(gridApi, dataSourceparams, null, null, selectedColumns)
            throw err;
          });
      }
    }

  }


  function onClick(type) {
    setIsSelect(type)
    let temp = tempColumns
    temp.forEach((item) => {
      item.checked = type
    })
    setTempColumns(temp)
  }

  const handleNotesCancel = async () => {
    setShowNotesDialog(false);
  };

  return (
    <>
      {currentpage !== 'overview' &&
        <div className="header-top">

          <div className="card-toolbar btns">

            {
              <Link to='#' className='align-self-center' onClick={() => clearFilters(gridApi)} >
                <i className="fas fa-sync"></i> Clear filters
            </Link>
            }
            &nbsp;
            {
              <Link to='#' className='align-self-center' onClick={() => setshowLifecycle(true)} >
                <i className="bi-app-indicator"></i> Lifecycle
            </Link>
            }
            {!searchcolumns && (userPermission && userPermission && userPermission.canBulkDelete) &&
              <Link to="#" className='align-self-center' onClick={() => enableBulkUpdates && handleBulkDelete(gridApi)} ><i className="fas fa-trash-alt"></i> {'Bulk delete'}</Link>
            }
            {

              <Link to="#" className='align-self-center' onClick={() => setFileUploadOpen(true)} >
                <i className="fas fa-upload"></i> {'Import file'}
              </Link>
            }
            <Link className='align-self-center' to="#" onClick={() => (enableBulkUpdates) ? exportExcelData() : setShowExport(true)}><i className="fas fa-download"></i> Export file</Link>

            {/* <Link className='align-self-center' to="#" onClick={() => exportExcelData()}><i className="fas fa-download"></i> Export file</Link> */}
            {
              <div className="add-agg"><Link onClick={() => setShowAssetDetail(true)} to="#" className='align-self-center'>
                <Button variant="contained" color="primary" className={classes.button}>
                  <i className="fas fa-plus" style={{ color: 'white' }}></i> Create Asset
              </Button>
                {/* <button type="button" className="btn btn-primary">Create Asset</button> */}
              </Link></div>
            }
          </div>

        </div>
      }
      <div className={`card card-custom gutter-b bg-smoke ${currentpage !== 'overview' ? 'padd' : ''}`}>
        {
          currentpage !== 'overview' && totalAssetValue && <div>
            <span>Total Asset value: </span><span>{SVformatter(totalAssetValue)} kr</span>
          </div>
        }

        <div className='card-header border-0 card-header-sm'>
          {currentpage !== 'overview' &&
            < Drawer
              columnDefs={tempColumns}
              setTempColumnDefs={setTempColumns}
              submitFilter={submitFilter}
              onClick={onClick}
              setIsSelect={setIsSelect}
              isSelect={isSelect}
            />
          }
          <div className={`card-title ${page}-title`}>
            <h3 className='card-label text-bold'>
              {'Inventory'}
            </h3>
          </div>
        </div>
        <div
          className='ag-theme-quartz'
          style={{
            width: '100%',
            height: `${currentpage === 'overview' ? '498px' : '80vh'}`,
            boxShadow: '0 1px 15px 1px rgba(69,65,78,.08)',
          }}
        >

          <AgGridReact
            ref={gridRef}
            columnDefs={buildColumnDefinitions(columnDefs)}
            pagination={true}
            rowModelType={'infinite'}
            paginationPageSize={rowsPerPage}
            cacheBlockSize={rowsPerPage}
            onGridReady={onGridReady}
            defaultColDef={defaultColDef}
            onRowSelec1ed={onRowSelected}
            onRowDataChanged={onRowDataChanged}
            onRowEditingStarted={onRowEditingStarted}
            enableCellTextSelection={true}
            overlayLoadingTemplate={overlayLoadingTemplate}
            overlayNoRowsTemplate={noRowsTemplate}
            onRowSelected={onRowSelected}
            // gotoEdit={gotoEdit}
            enableBulkUpdates={enableBulkUpdates}
            searchString={searchString}
            deleteConfirm={deleteConfirm}
            handleNotestOpen={handleNotestOpen}
            applyColumnDefOrder={true}
            suppressRowClickSelection={true}
            alwaysShowVerticalScroll={true}
            quickFilterText={quickFilterText}
            userPermission={userPermission}
            onModelUpdated={onModelUpdated}
            components={frameworkComponents}
            getRowHeight={20}
            cacheOverflowSize={100}
            maxConcurrentDatasourceRequests={-1}
            infiniteInitialRowCount={1}
            maxBlocksInCache={rowsPerPage}
            rowSelection='multiple'
            suppressHeaderContextMenu={true}
          // sideBar={sideBar}
          // sideBar={
          //   {
          //     maxWidth: 600,
          //     toolPanels: [
          //       {
          //         id: 'columns',
          //         labelDefault: 'Columns',
          //         labelKey: 'columns',
          //         iconKey: 'columns',
          //         toolPanel: 'agColumnsToolPanel',
          //       },
          //       // {
          //       //   id: 'filters',
          //       //   labelDefault: 'Filters',
          //       //   labelKey: 'filters',
          //       //   iconKey: 'filter',
          //       //   toolPanel: 'agFiltersToolPanel',
          //       // },
          //       // {
          //       //   id: 'customStats',
          //       //   labelDefault: 'Custom Stats',
          //       //   labelKey: 'customStats',
          //       //   iconKey: 'custom-stats',
          //       //   toolPanel: 'customStatsToolPanel',
          //       // },
          //     ],
          //   }
          //   //defaultToolPanel: 'customStats'
          // }
          />
        </div>
        <DeleteConfirmDialog
          title="Inventory"
          content="are you sure you want to delete this Inventory ?"
          open={showDeleteDialog}
          setOpen={setShowDeleteDialog}
          tableName="purchased_assets"
          deleteId={deleteId}
          tempFieldsList={tempFieldsList}
          getNewData={lists}
          parentGridApi={gridApi}
        />
        <LifeCycleDialog
          handleCancel={setshowLifecycle}
          // userRole={userRole}
          showDialog={showLifecycle}
        />

        <FileUpload
          open={fileUploadOpen}
          setOpen={setFileUploadOpen}
          title='Inventory File upload'
          handleCancel={setFileUploadOpen}
          getNewData={lists}
          parentGridApi={gridApi}
          tempFieldsList={tempFieldsList}
          userInfo={userInfo}
          dataSourceparams={dataSourceparams}
        />
        <AssetViewDialog
          open={showAssetDetail}
          setOpen={setShowAssetDetail}
          fieldDisable={true}
          setLoading={setLoading}
          page="view"
          title={`Inventory`}
          assetId={selectedAssetId}
          setSelectedAsset={setSelectedAssetId}
          userPermission={userPermission}
          isInventory={true}
          handleAssetsList={lists}
          setShowAssetCreate={setShowAssetDetail}
        />
      </div>

      <ExportModal
        page='invenory'
        open={showExport}
        setOpen={setShowExport}
        lists={lists}
        parentGridApi={gridApi}
        // filterValues={filterValues}
        totalRows={totalRowDatas}
        isDownloaded={isDownloaded}
        tempFieldsList={tempFieldsList}
        dataSourceparams={dataSourceparams}
      />
      <NotesDialog
        handleCancel={handleNotesCancel}
        // userRole={userRole}
        selectedItem={selectedAsset}
        showDialog={showNotesDialog}
        collection="purchased_assets"
        userPermission={userPermission}
      />
    </>
  )
}

